/* body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

/* #app-wrapper {
  display: flex;
  background-color: #efefef;
} */

/* #action-panel {
  background-color: lightblue;
  width: 200px;
  position: sticky;
  top: 0;
  align-self: flex-start;
  height: calc(100vh - 3.75rem);
} */

/* #action-panel {
  background-color: lightblue;
  width: 100%;
  position: sticky;
  top: 0;
  align-self: flex-start;
  height: 100vh;
} */

#pdf-viewer {
  margin: 0 auto;
  background-color: #efefef;
}

/* #details-panel {
  background-color: lightgreen;
  width: 200px;
  position: sticky;
  top: 0;
  align-self: flex-start;
  height: calc(100vh - 3.75rem);
} */

/* .page-wrapper {
  border: 2px solid darkblue;
  margin-bottom: 10px;
} */

.page-annotations {
  position: absolute;
  z-index: 100;
}

/* .draggable,
.resizable {
  padding: 5px;
} */

/* .draggable:hover,
.draggable:active,
.resizable:hover,
.resizable:active,
.selected {
  border: 2px blue dotted;
} */

.draggable,
.resizable {
  /* overflow: hidden; */
}

.draggable input[type="checkbox"] {
  margin: 0;
  padding: 0;
}

.draggable input[type="text"],
.resizable input[type="text"],
.draggable input[type="date"],
.resizable input[type="date"],
.draggable input[type="time"],
.resizable input[type="time"] {
  border: 0;
  min-width: 20px;
  width: 100%;
  height: 100%;
  /* background-color: aqua; */
  font-size: inherit;
  font-family: inherit;
}

.draggable textarea,
.resizable textarea {
  min-width: 50px;
  width: 100%;
  height: 100%;
  resize: none;
  font-size: inherit;
  font-family: inherit;
  /* background-color: aqua; */
}

.ui-draggable-handle {
  /* border: 2px dotted blue; */
}

/* .draggable:before {
  content: '';
  position: absolute;
  left: -14px;
  top:-20px;
  font-size: 20px;
  line-height: 20px;
  color: #ac14c2;
  /* text-shadow: 0 5px #ac14c2, 0 10px #ac14c2, 5px 0 #ac14c2, 5px 5px #ac14c2, 5px 10px #ac14c2, 10px 0 #ac14c2, 10px 5px #ac14c2, 10px 10px #ac14c2; 
} */

.draggable {
  position: absolute;
  z-index: 100;
}

.drag-handle {
  position: absolute;
  top: -10px;
  left: -15px;
  width: 15px;
  height: 15px;
  /* background-color: red; */
  z-index: 100;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB2AAAAdgB+lymcgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAK6SURBVHic7ZvNbtNAEMd/TXvJDQlRlY+C+BKIlqoSCeJ1OCHES/EQFVD1FSIE/RICBGqBAhEU5cQBaDjYI5KQ2Lv2zq6T9U+aS2RP/jOOs7szu1ATN/MBv7sJPADawC7wO6AW75wGOkA/tU76WRQsAi/5F7zYPnAuoC4vLJH83EeDF3sFXAimTpmLwBsmBy/2HrgaSKMal4F35AcvdghcD6JUgZvAR8yDF/sC3A6g1ynrQBf74MW+kwyTU8kd4BvFgxf7AdzzrL00baBH+eDFekDLawQl2cJd8GLPNIQ2NJwCv6bEpxqrwA7unv42cEtD6JyG0wz6htctAH80hQhar8DUUCcgtIAJmL4qpalqArxRJ8Dy+jbJJOcJyVBXFdZING2hOGO8y/D0dreAD9Nx3/bB7A/c20u1OmUdOGZY5EkBP6YJsJ2fnIzcf5xqdsIN4PMEobaYJsCGuQk+usBKAY1DXAM+ORJKhp8yfhsZfr6SFGQKsUxSn3MllBxfRf3O5/j6QFKSs+I88NZCcNXtALhkGvwZYK8Col3ba+BsXvCngOcVEKtl22R0oJoMt6tm1TpprP/xqALifNlDCXpwxtUfl5WYiP4VgMj/BIWoh0Eh6omQYDIVtt1iYyrYlixfhabCQt5iyGbZOmnVppmAUoshwdVy2HcCnCyHBRcFEc0EqBZEhNGS2I7l/ZoJGBy1VEpiQgvYBDaw79VlFS7KJmA11bSJZVHUZ2+wgXm/z5sun30B341YI+rGSGgBoalfgdACQqOVgDWG21V97Pb4jA6Le1SrF5nLU8qt3sbZhoZQrV/AwpT4VKNF5BslIfKtssIKcETx4LsorOp8E/V2ecH2wMQBM3RgQrA5MnMlkEZ1lsjeQzzTh6aEReAF42d7xnX7aacyBydDrtCawH2SBDwGfgbUUhMtfwELMwo6KOZ7HgAAAABJRU5ErkJggg==");
  background-size: contain;
  visibility: hidden;
  cursor: move;
}

.delete-handle {
  position: absolute;
  top: -10px;
  right: -15px;
  width: 15px;
  height: 15px;
  z-index: 100;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC+klEQVR4nO2aO2gVQRSGf0WDpbcy5tEETApt1E4MKCjaiIJGRHw0ElCLIKjRyjQGUSzEB0QbTaUxoKCoELTQdD4KQdBCEBStvJKEoEmRKwfOBRnOZJfNnX/3XueDU52ZO//5d3fOZrJAJBKJRCKhaQcwCmASQCUhZMwDAF1ooOJ/pijcjbLOrXtGMxRfjRE0AJMLMGACDUDFiVqPLzyV/8GAEoD1AA4BuKDP7hsA0wu4/asxC+AzgDEAQwD6AGwB0AFgcR5F9gDoBzCsRU7UoMis8UfNeaTG96o5LSEMqNRZRANqTaXOIhIJxLTeYr/1fV3iu+7GH7QzSIxr6xrTXXpEO8eQxhXduSUGtLP06U7eqy21R2OH7u4btRNJrNZW2KG7vnSpZSDwpQDPty9EW3BeF6BQX4i24DwtQKG+eMIwYLgAhfpCtAXncgEK9YVoC87ZAhTqizMMA454Fn8MoBVAmz6L8wmdAXBa21eLtsCZhDlpfl+0BWenZ3ERVqU9oRgp2KU/YU6a399FqB8bPIsvdcbNV8xK43ebE+b8S5NnjGgLTqdn8eXOuLTFZJlT8oxZBQIlz+KtGYvJMqct5UUIwiI9pnIX78xYTJY5XUZ+VrVR+GEIWJuxmCxz1hl50UTjvSFA/lJjGdBt5EUTjReGgG1EA7Yb+ecgcs8QsJtowB4jfxdErhkC5ACDZcBhI38VRM4ZAo4SDThm5EUTjeOGgJNEA04ZeTGFxt4UVyCkAQNGXs4OaWw2BFwkGnDJyG8CkTWGgOtEA24YedFEo9kQcJtowB0jvwJElgCYcwTcJxrgfnYzp5qolB0RclrMMuCZk5OPsOh8ckS8JBrwysl9RA6MOyLeEg145+TEEDoPE65CSAPcu08+rKRzyxHxlWjANyd3Ezkw6IiQTZFlwC8ndx45cMIRIef6LAPcIznRQudggmBmHMjDgO4CFO47jqPQZDyLeUTZ+KcMjf0FMGAfcmarvohMEYue0jdP+W4oEolEIpFIBFn4C4n1BdZ/MqXKAAAAAElFTkSuQmCC");
  background-size: contain;
  visibility: hidden;
  cursor: pointer;
}

.duplicate-handle {
  position: absolute;
  bottom: -10px;
  left: -15px;
  width: 20px;
  height: 20px;
  z-index: 100;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFMSURBVEiJ3dM/SxxRFAXwX7IpLNzKQkg60VKw9R8ErCUfRFt7Sw0BG7Ww9zNIcMFGJI2wWGyTWFgIaimIxkaLeROes/Nm3woW5sCFee9ezplz373875jGPn5iPtzt4jBE9f4Aa/hUEvz7qMEoOmjhNPOHxvAdT/gxqHg2FC5nkpc4VThDs4MbXKI3pMCv+PChkhzFDEYyyS7wO1d5GteKtgwTOxWeuRB92H8FeRlTEU8nBPgYJT7nWq3Bl+i7FaJP4E2QK7CKk8zansTkHUn3GNYb8l9Tak178IA93IfzMTaxKDElAWWuz3HVwTnaNQQrmh0kp6iKCdzqb9F2on4S3xSLelVeNrUoRlu9mxjHGFcs68awAl2D92QhCHRxN6zAREbNnxAvEL/BY6ZYHf6mErFAJ1U0ANc4SyXjFm0ppmRJfuvKB70bVPh+8QzR4mdrG+IMKwAAAABJRU5ErkJggg==");
  background-size: contain;
  visibility: hidden;
  cursor: pointer;
}

.selected .drag-handle,
.selected .delete-handle,
.selected .duplicate-handle,
.draggable:hover .drag-handle,
.draggable:hover .delete-handle,
.draggable:hover .duplicate-handle {
  visibility: visible;
}

.drag-handle:hover,
.drag-handle:active {
  visibility: visible;
}

.ui-resizable-n,
.ui-resizable-s {
  margin-left: 5px;
}

.ui-resizable-n {
  top: -3px;
}

.ui-resizable-e {
  right: -8px;
}

.ui-resizable-w {
  left: -4px;
}

.droppable {
}

.draggable:hover,
.resizable:hover {
  outline: 2px dotted blue;
  outline-offset: 2px;
  z-index: 200;
}

.selected,
.selected:hover {
  outline: 2px dotted orange;
  outline-offset: 2px;
  z-index: 200;
}

/* .signature-panel {
  border: 1px solid black;
  background-color: #e9e9e9;
}

.signature-panel-canvas {
  background-color: white;
} */

/* 
.signer-block {
  border: 1px solid black;
}

.signer-block-details {
  margin-top: 5px;
  margin-bottom: 5px;
}

.signer-block-details label {
  font-weight: bold;
}

.signer-block input {
  width: 95%;
} */

/* .line-element {
  background-color: black;
} */

.signature-placeholder {
  width: 100%;
  height: 100%;
  background-color: lightgray;
  text-align: center;
  font-size: 0.8rem;
}

.signature-placeholder.active {
  background-color: cyan;
  cursor: pointer;
}

/* .signer-labels {
  margin: 0;
} */

/* .select-signers {
  width: 100%;
} */

/* .margin-top-1rem {
  margin-top: 1rem;
} */

.delete-signer {
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC+klEQVR4nO2aO2gVQRSGf0WDpbcy5tEETApt1E4MKCjaiIJGRHw0ElCLIKjRyjQGUSzEB0QbTaUxoKCoELTQdD4KQdBCEBStvJKEoEmRKwfOBRnOZJfNnX/3XueDU52ZO//5d3fOZrJAJBKJRCKhaQcwCmASQCUhZMwDAF1ooOJ/pijcjbLOrXtGMxRfjRE0AJMLMGACDUDFiVqPLzyV/8GAEoD1AA4BuKDP7hsA0wu4/asxC+AzgDEAQwD6AGwB0AFgcR5F9gDoBzCsRU7UoMis8UfNeaTG96o5LSEMqNRZRANqTaXOIhIJxLTeYr/1fV3iu+7GH7QzSIxr6xrTXXpEO8eQxhXduSUGtLP06U7eqy21R2OH7u4btRNJrNZW2KG7vnSpZSDwpQDPty9EW3BeF6BQX4i24DwtQKG+eMIwYLgAhfpCtAXncgEK9YVoC87ZAhTqizMMA454Fn8MoBVAmz6L8wmdAXBa21eLtsCZhDlpfl+0BWenZ3ERVqU9oRgp2KU/YU6a399FqB8bPIsvdcbNV8xK43ebE+b8S5NnjGgLTqdn8eXOuLTFZJlT8oxZBQIlz+KtGYvJMqct5UUIwiI9pnIX78xYTJY5XUZ+VrVR+GEIWJuxmCxz1hl50UTjvSFA/lJjGdBt5EUTjReGgG1EA7Yb+ecgcs8QsJtowB4jfxdErhkC5ACDZcBhI38VRM4ZAo4SDThm5EUTjeOGgJNEA04ZeTGFxt4UVyCkAQNGXs4OaWw2BFwkGnDJyG8CkTWGgOtEA24YedFEo9kQcJtowB0jvwJElgCYcwTcJxrgfnYzp5qolB0RclrMMuCZk5OPsOh8ckS8JBrwysl9RA6MOyLeEg145+TEEDoPE65CSAPcu08+rKRzyxHxlWjANyd3Ezkw6IiQTZFlwC8ndx45cMIRIef6LAPcIznRQudggmBmHMjDgO4CFO47jqPQZDyLeUTZ+KcMjf0FMGAfcmarvohMEYue0jdP+W4oEolEIpFIBFn4C4n1BdZ/MqXKAAAAAElFTkSuQmCC");
  background-size: contain;
  cursor: pointer;
}

/* .remove-highlight input,
.remove-highlight textarea,
.remove-highlight .highlightable-field {
  background-color: transparent !important;
} */

/* .highlightable-field {
  background-color: aqua;
} */

/* .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
} */

/* .signature-panel-wrapper {
  position: fixed;
  top: 25%;
  left: 25%;
  z-index: 300;
  width: 50%;
  height: 50%;
  background-color: white;
  margin: 0 auto;
  padding: 1rem;
} */

.signature-panel-canvas {
  width: 95%;
  height: 50%;
  border: 2px solid black;
  margin: 10px;
}

/* .add-signer-modal {
  position: fixed;
  top: 25%;
  left: 25%;
  z-index: 300;
  width: 50%;
  height: 50%;
  background-color: white;
  margin: 0 auto;
  padding: 1rem;
} */
