/* @import url("https://font7s.googleapis.com/css2?family=Dancing+Script&display=swap"); */

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app-wrapper {
  display: flex;
}

#pdf-viewer-sign {
  margin: 0 auto;
  /* background-color: lightgrey; */
}

/* .page-wrapper {
  border: 1px solid darkblue;
  margin-bottom: 10px;
} */

.signable-element,
.page-annotations {
  position: absolute;
  z-index: 100;
}

.draggable input[type="checkbox"] {
  margin: 0;
  padding: 0;
}

.draggable,
.resizable {
  overflow: hidden;
}

.draggable input[type="text"],
.resizable input[type="text"],
.draggable input[type="date"],
.resizable input[type="date"],
.draggable input[type="time"],
.resizable input[type="time"] {
  border: 0;
  min-width: 20px;
  width: 100%;
  height: 100%;
  /* background-color: aqua; */
  font-size: inherit;
  font-family: inherit;
}
.draggable textarea,
.resizable textarea {
  min-width: 50px;
  width: 100%;
  height: 100%;
  resize: none;
  /* background-color: aqua; */
  font-size: inherit;
  font-family: inherit;
}

.draggable {
  position: absolute;
  z-index: 100;
}

.signature-panel {
  border: 1px solid black;
  background-color: #e9e9e9;
}

.signature-panel-canvas {
  background-color: white;
}

.signer-block {
  border: 1px solid black;
}

.signer-block-details {
  margin-top: 5px;
  margin-bottom: 5px;
}

.signer-block-details label {
  font-weight: bold;
}

.signer-block input {
  width: 95%;
}

/* .line-element {
  background-color: black;
} */

.signature-placeholder {
  width: 100%;
  height: 100%;
  background-color: lightgray;
  text-align: center;
  font-size: 0.8rem;
}

.signature-placeholder.active {
  background-color: cyan;
  cursor: pointer;
}

/* .signer-labels {
  margin: 0;
} */

/* .select-signers {
  width: 100%;
} */

/* .margin-top-1rem {
  margin-top: 1rem;
} */

/* .remove-highlight,
.remove-highlight input,
.remove-highlight textarea,
.remove-highlight .highlightable-field {
  background-color: transparent !important;
} */

/* .highlightable-field {
  background-color: aqua;
} */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.signature-panel-wrapper {
  position: fixed;
  top: 25%;
  left: 25%;
  z-index: 300;
  width: 50%;
  height: 50%;
  background-color: white;
  margin: 0 auto;
}

.signature-panel-canvas {
  width: 95%;
  height: 50%;
  border: 2px solid black;
  margin: 10px;
}
